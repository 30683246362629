import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import moment from "moment";

import Layout from "../components/Layout";
import SEO from "../components/seo";

// import "@wordpress/block-library/build-style/common.css";
// import "@wordpress/block-library/build-style/reset.css";
// import "@wordpress/block-library/build-style/elements.css";
// import "@wordpress/block-library/build-style/style.css";
// // import "@wordpress/block-library/build-style/theme.css";
// //import "@wordpress/block-library/build-style/classic.css";

// import "../styles/wordpress-styles.css";

function PageTemplate({ data }) {
  const post = data.wpPage;
  return (
    <Layout>
      <SEO keywords={["Hanna Roos"]} title={post.title} />
      <div className="container px-2 mx-auto mb-6" style={{ marginTop: 150 }}>
        <section className="flex flex-col items-center md:flex-row">
          <div className="max-w-3xl mx-auto">
            <h1 className="mb-6 text-3xl text-center font-header md:text-6xl">
              {post.title}
            </h1>
            <div
              className="mt-24 space-y-4 prose prose-pre:bg-transparent prose-pre:text-black prose-pre:font-regular"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
        </section>
      </div>
    </Layout>
  );
}

PageTemplate.propTypes = {
  data: PropTypes.any,
};

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      content
      slug
    }
  }
`;

export default PageTemplate;
